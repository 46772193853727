import * as repo from "@/repositories/prices";
import { mutation, status } from "./data";

export async function fetchPrices({ commit }) {
  try {
    let plans = await repo.getPrices();
    // let plans=  {
    //   "plans": [
    //     {
    //       "index": 1,
    //       "cost": 0,
    //       "priceId": null
    //     },
    //     {
    //       "index": 2,
    //       "cost": 4.99,
    //       "priceId": "price_1ISRIhJt0w4lJiZV51UoN6D8"
    //     },
    //     {
    //       "index": 3,
    //       "cost": 14.99,
    //       "priceId": "price_1ISRJKJt0w4lJiZVGYrQyTaY"
    //     },
    //     {
    //         "index": 4,
    //         "cost": 89.9,
    //         "priceId": "price_1QV9oNJt0w4lJiZVrZYYrrGZ"
    //     },
    //     {
    //       "index": 5,
    //       "cost": 30,
    //       "priceId": "price_1QV9oNJt0w4lJiZVrZYYrrGZ"
    //     },
    //     {
    //       "index": 6,
    //       "cost": 40,
    //       "priceId": "price_1QV9oNJt0w4lJiZVrZYYrrGZ"
    //     },
    //     {
    //       "index": 7,
    //       "cost": 50,
    //       "priceId": "price_1QV9oNJt0w4lJiZVrZYYrrGZ"
    //     }
    //   ],
    //   "currency": "EUR"
    // };

    plans.plans = plans.plans.slice(0, 3);
    plans.plans.push({
      "index": 4,
      "cost": 89.9,
      "priceId": "price_1QV9BUJt0w4lJiZV3NiEUbQ6"
    });

    commit(mutation.setPlans, plans);
    commit(mutation.setState, status.SuccessFetchPrices);
  } catch (e) {
    console.error("error in promise prices", e);
    commit(mutation.setState, status.FailureFetchPrices);
  }
}

export async function cancelSubscription({ commit }, payload) {
  try {
    await repo.cancelSubscription(payload);

    commit(mutation.setState, status.SuccessCancelSubscription);
  } catch (e) {
    commit(mutation.setState, status.FailureCancelSubscription);
    throw e;
  }
}

export async function createSubscription({ commit }, payload) {
  try {
    const res = await repo.createSubscription(payload);
    commit(mutation.setState, status.SuccessCreateSubscription);
    return res;
  } catch (e) {
    commit(mutation.setState, status.FailureCreteSubscription);
    throw e;
  }
}

export async function addPromoCode({ commit }, payload) {
  try {
    const code = await repo.setPromoCode(payload);
    commit(mutation.setPromoCode, code);
    commit(mutation.setState, status.SuccessPromoCode);
    commit(mutation.setPromoCodeError, null);
  } catch (e) {
    if (e.responseContent) {
      commit(mutation.setPromoCodeError, e.responseContent);
    }
    commit(mutation.setState, status.FailurePromoCode);
    throw e;
  }
}

export async function removePromoCode({ commit }, payload) {
  console.log("removePromoCode");
  commit(mutation.setPromoCode, null);
}